.teams__container {
  display: flex;
  margin: 0.5em;
  margin-top: -2px;
  min-height: 150px;
  position: relative;
  margin-bottom: 15px;
}

.teams__a {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%);
  z-index: 200;
}

.date-time__container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #444670;
  font-size: 1em;
  margin-top: 0.8em;
}

.date-time__img {
  margin-right: 5px;
  margin-bottom: 10px;
  width: 13px;
}

.date-time__text {
  margin-top: 5px;
}

.court__container {
  text-align: center;
  margin-top: -2.5em;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 700px;
}

.court__opacity {
  position: absolute;
  right: 11.3em;
  margin-top: 4px;
  /* position: absolute;
  top: 41px;
  margin-left: 12px; */
}

.single-team__container {
  position: relative;
  flex: 1;
  text-align: center;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  min-height: 134px;
  z-index: 100;
}

.single-team__name-shirt {
  display: block;
  color: #444670;
  font-weight: bold;
  font-size: 1.2em;
}

.single-team__spread-sign {
  position: relative;
  border: solid 1px #ebebeb;
  min-width: 65px;
  height: 55px;
  position: absolute;
  bottom: -70px;
  left: 50%;
  transform: translate(-50%);
  background-color: white;
  border-radius: 3px;
}

.single-team__spread-sign-text {
  margin-top: 5px;
  color: #2b5a64;
  font-weight: 500;
  font-size: 35px;
}

.pin-top {
  position: absolute;
  width: 5px;
  height: 10.05px;
  top: -2px;
  left: 50%;
  background: linear-gradient(180deg, #ffffff 0%, #efefef 100%);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  transform: rotate(-90deg);
}
.pin-bottom {
  position: absolute;
  width: 5px;
  height: 10.05px;
  bottom: -2px;
  left: 50%;
  background: linear-gradient(180deg, #ffffff 0%, #efefef 100%);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  transform: rotate(-90deg);
}

.sub-header__container {
  background-color: red;
  display: block;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90.98deg, #bc434a 0%, #f88e53 100%);
  padding: 0.2rem;
}

.sub-header__round {
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

.sub-header__pick {
  text-align: center;
  padding: 0 1em;
  color: white;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
}

.button__container {
  text-align: center;
}

.button__button {
  width: 200px;
  background-color: #1bd4a5;
  margin: -4px auto;
  margin-bottom: 25px;
  border-radius: 50px;
  color: white;
  padding: 10px 15px;
  box-sizing: border-box;
}

.logo-header__container {
  display: flex;
  text-align: center;
  background: linear-gradient(0deg, #2b5a64, #2b5a64);
}

.logo-header__brand-name {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 0.7rem;
}

.logo-header__brand-name,
.logo-header__game-name {
  color: white;
}

.logo-header__img {
  margin: 0.2em;
  flex: 1;
}

.logo-header__img-tag {
  height: 40px;
  margin-right: 3em;
}

.logo-header__game-name {
  flex: 1;
}

.logo-header__empty {
  flex: 1;
}

.logo-header__hit {
  margin-top: 0.2em;
  font-weight: bold;
}

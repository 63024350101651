.score-spread__spreads {
  /* padding-left: 20vw; */
  text-align: center;
}

.score-spread__to-win {
  font-weight: 700;
  font-size: 20px;
  color: #9d9d9d;
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translate(-50%);
  min-width: 500px;
}

.score-spread__spreads {
  position: relative;
}

.score-spread__points-text {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: -8px;
  color: #9d9d9d;
  font-weight: bold;
}

.score-spread__container {
  margin-bottom: 1.7em;
  margin-top: 1em;
}

.score-spread__costum-border-buttom {
  width: 60px;
  height: 1px;
  background-color: #e2e2e2;
  position: absolute;
  top: -1em;
  left: 50%;
  transform: translate(-50%);
  border-radius: 50px;
}

.score-spread__costum-border-top {
  width: 60px;
  height: 1px;
  background-color: #e2e2e2;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%);
  border-radius: 50px;
}

.fake-submit__container {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(23, 93, 16, 1) 61%,
    rgba(0, 212, 255, 1) 100%
  );

  height: 100vh;
  color: white;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 4em;
}

.fake-submit__text-wrapper {
  display: block;
  font-size: 1.2em;
}

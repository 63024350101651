body {
  font-family: "Roboto Condensed", sans-serif !important;
  background-color: #fff4f4 !important;
  height: 100vh !important;
}

.jss4 {
  font-size: 10px !important;
}

.Carousel-indicatorIcon-4 {
  font-size: 10px !important;
}
